import { Send } from "@mui/icons-material";
import { Box, TextField, Typography, Button, Card } from "@mui/material";
import React from "react";

const Contact = () => {
  return (
        <Box sx={{ borderTop:"2px solid black" , }}>
    <Box component={Card}
      sx={{
        padding: "5%",
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0,0,0,1)",
        boxShadow:10,
        mt:{xs:'50px',md:'100px'}
        

      }}
    >
      <Typography gutterBottom textAlign={"center"} variant="h3"  color={'white'} >
        Get in Touch!
      </Typography>

      <Box
        component="form"
        autoComplete="off"
        action="https://formsubmit.co/87150fd5eae6a14bd884885cdc9550e8"
        method="POST"
        sx={{
          // transition: "ease-in , short",
          
          // width:'100%',
          display: "flex",
          flexDirection: "column",
          // justifyContent: "space-evenly",
          alignItems: "center",
          // flexWrap: "wrap",
          margin:'5% auto',
          "& label":{color:'white'},
          "& label.Mui-focused": {
            color: "white",
          },
          // '& .MuiInput-underline:after': {
          //   borderBottomColor: 'red',
          // },
          "& .MuiOutlinedInput-root": {
            color:'white',
            "& fieldset": {
              borderColor: "white",
              color: 'white',
            },
            "&:hover fieldset": {
              borderColor: "#607d8b",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#607d8b",
            },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            mb: "1%",
          }}
        >
          <TextField
            sx={{
              width: { xs: "100%", sm: "50%" },
              mb: {xs:"3%", sm:'1%'},
              
              mr: { sm: "1%" },
            }}
            label="Name"
            name="name"
            required
            
            // variant="filled"
          />
          <TextField
            sx={{
              width: { xs: "100%", sm: "50%" },
              mb: {xs:"3%", sm:'1%'},
              ml: { sm: "1%" },
            }}
            label="Email"
            name="email"
            type="email"
            required
            // variant="filled"
          />
        </Box>

        <TextField
          fullWidth
          multiline
          rows={10}
              sx={{     mb:{xs:'3%',sm:'1%'}           }}
          label="Message"
          name="msg"
          required
          // variant='filled'
        />
        {/* <input
          type="hidden"
          name="_next"
          value="https://kevinjacob.dev/thanks.html"
        ></input> */}

        <Button
          fullWidth
          type="submit"
          sx={{
            margin: "1% auto",
            color: "white",
            borderColor: "white",
            "&:hover": { bgcolor: "#607d8b", borderColor: "black" ,},
          }}
          variant="outlined"
          endIcon={<Send />}
        >
          Send
        </Button>
      </Box>
    </Box>
    </Box>
  );
};

export default Contact;

import { Box, Button, Card, Chip, Link, Typography } from "@mui/material";
import React from "react";

const Project = ({ name, id, description, image, codeL, liveL, stack }) => {
  return (
    <Box
      component={Card}
      sx={{
        margin: "10 auto%",
        mb: "10%",
        bgcolor: "black",
        padding: "5%",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-around",
        alignItems: "stretch",
        // width:'100%'
        // backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0,0,0,1)",
        boxShadow:10,
      }}
    >
      <Box
        sx={{
          display: "flex",
          margin: "3%",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Typography
          color="cardText"
          gutterBottom
          variant="h3"
          sx={{ mb: "15%", fontSize: { xl: "5rem" } }}
        >
          {name}
        </Typography>
        <Box sx={{ mb: "10%" }}>
          {stack.map((tag) => (
            <Chip
              sx={{
                bgcolor: "white",
                margin: "5px",
                fontSize: { sm: "1rem", xl: "1.5rem" },
              }}
              label={tag}
            />
          ))}
        </Box>

        <Typography
          color="cardText"
          variant="subtitle2"
          paragraph
          sx={{ fontSize: { xs: "1.2rem", lg: "1.5rem", xl: "2rem" } }}
        >
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Link target="_blank" href={liveL}>
          <Box
            component="img"
            src={image}
            sx={{
              width: "100%",
              height: "auto",

              margin: "1% auto",
              border: "10px double white",
              boxSizing: "border-box",
            }}
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <Button
            variant="outlined"
            size="large"
            target="_blank"
            href={liveL}
            sx={{
              textDecoration: "none",
              margin: "2%",
              width: "100%",
              fontSize: { xs: "1rem", lg: "1.5rem" },
              // borderColor: "orange",
              // color:'white',
              // "& .MuiButton-outlined":{  "&hover":{borderColor:'white'}},
             
            }}
          >
            Live
          </Button>

          <Button
            variant="outlined"
            target="_blank"
            href={codeL}
            sx={{
              textDecoration: "none",
              margin: "2%",
              width: "100%",
              fontSize: { xs: "1rem", lg: "1.5rem" },
            }}
          >
            Code
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Project;

import {
  Box,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Card,
} from "@mui/material";
import { Link as ReactLink } from "react-router-dom";
import "./App.css";
import Contact from "./components/Contact";
import HomeRepairServiceIcon from "@mui/icons-material/HomeRepairService";
import CreateIcon from "@mui/icons-material/Create";
import HandymanIcon from "@mui/icons-material/Handyman";
const App = () => {
  return (
    <Box
      // component={Card}
      sx={{
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        padding: "5%",
        // pt: "1%",
        margin: "0",

        // pb: "5%",
        // backdropFilter: "blur(5px)",
        // backgroundColor:"rgba(255,255,255,0.1)",
        // backgroundColor:"rgba(0,0,0,0.6)"
      }}
    >
      <Typography
        sx={{
          fontFamily: "Bonheur Royale, serif",
          fontWeight: "500",
          fontSize: { xs: "100px", sm: "175px", md: "250px", xl: "350px" },
          borderBottom: "2px groove black",
          pb: "2%",
        }}
        gutterBottom
        variant="h1"
      >
        Kevin Jacob
      </Typography>

      <Typography
        gutterBottom
        variant="h3"
        fontSize={{ xs: "1.8rem", sm: "2.5rem", md: "3.5rem" }}
      >
        Web Developer & Designer
      </Typography>
      <Typography fontStyle={"italic"} variant="h6">
        Based in
      </Typography>

      <Typography
        gutterBottom
        variant="h4"
        fontSize={{ xs: "1.4rem", md: "2rem" }}
      >
        Toronto, Canada
      </Typography>
      <Box
        component="img"
        src="/profile.jpg"
        sx={{
          width: { xs: "250px", md: "400px" },
          borderRadius: "50%",
          margin: "5% auto",
          boxShadow: 10,
        }}
      />

      <Box
        sx={{
          // mt:'5%',
          backgroundColor: "rgba(255,255,255,0.7)",
          boxShadow: 10,
          borderRadius: "4px",
          mb: { xs: "50px", md: "100px" },
        }}
      >
        <Typography
          paragraph
          variant="subtitle1"
          sx={{
            margin: "5% auto",
            padding: "5%",
            pb: 0,
            fontSize: { xs: "1.2rem", sm: "1.5rem", xl: "2rem" },
            width: { md: "50%" },
          }}
        >
          Hello, I'm Kevin!
          <br /> I'm a self-taught developer looking for my next creative
          project. I enjoy building applications, designing websites, and
          solving coding problems.
        </Typography>
        <Link
          sx={{ textDecoration: "none", color: "black" }}
          component={ReactLink}
          to="/portfolio"
          target={"_top"}
        >
          <Button
            sx={{
              // width:'65%',
              m: "1% auto",
              bgcolor: "white",
              color: "black",
              border: "5px solid black",

              "&:hover": {
                borderColor: "black",
                bgcolor: "black",
                color: "white",
              },
              fontSize: { xs: "20px", sm: "40px" },
              boxShadow: 10,
            }}
            variant="contained"
            endIcon={
              <HomeRepairServiceIcon
                sx={{
                  width: { xs: "25px", sm: "50px" },
                  height: { xs: "25px", sm: "50px" },
                }}
              />
            }
            size="large"
          >
            PORTFOLIO
          </Button>
        </Link>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            width: "80%",
            margin: "5% auto",
            paddingBottom: "5%",
          }}
        >
          <List>
            <ListItem sx={{ borderBottom: "1px solid gray", mb: "15%" }}>
              <ListItemIcon>
                <HandymanIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h6",
                  fontSize: { sm: "1.5rem", xl: "2.2rem" },
                }}
                primary="I Use"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="React"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Material UI"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Gatsby.js"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Node.js"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Shopify"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="GitHub"
              />
            </ListItem>
          </List>
          <Divider flexItem orientation="vertical" />
          <List>
            <ListItem sx={{ borderBottom: "1px solid gray", mb: "15%" }}>
              <ListItemIcon>
                <CreateIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText
                primaryTypographyProps={{
                  variant: "h6",
                  fontSize: { sm: "1.5rem", xl: "2.2rem" },
                }}
                primary="I Write"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="HTML"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="CSS"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Javascript"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primaryTypographyProps={{
                  fontSize: { sm: "1.5rem", xl: "1.8rem" },
                }}
                inset
                primary="Liquid"
              />
            </ListItem>
          </List>
        </Box>
      </Box>

      <Contact />
    </Box>
  );
};

export default App;

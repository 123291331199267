import { Home } from "@mui/icons-material";
import { Box, IconButton, Typography, Link } from "@mui/material";
import React from "react";
import { Link as ReactLink } from "react-router-dom";
import Contact from "./Contact";
import Project from "./Project";

const Portfolio = () => {
  return (
    <Box
      sx={{
        padding: "5%",
        margin: "1% ",
        // backdropFilter: "blur(10px)",
        // backgroundColor: "rgba(255,255,255,0.1)",
        mb: "50px",
      }}
    >
      <Box
        sx={{
          borderBottom: "2px groove black",
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ fontSize: { xs: "70px", sm: "100px", xl: "175px" } }}
          gutterBottom
          variant="h1"
        >
          Portfolio
        </Typography>
        <IconButton sx={{ borderRadius: "10%" }}>
          <Link
            to="/"
            component={ReactLink}
            sx={{ textDecoration: "none", color: "black" }}
          >
            <Home fontSize="large" />
          </Link>
        </IconButton>
      </Box>
      <Box sx={{ margin: "5% auto" }}>
        <Project
          name="Pup's Perspective"
          description="A 5-page website made for a client providing dog training services"
          liveL="https://www.pupsperspective.com/"
          codeL="https://github.com/8080co/PupsPerspective"
          image="/pups.jpg"
          stack={["React", "Material UI"]}
        />
        <Project
          name="Bellas Recipes"
          description="A blog-style website designed for a client writing their own recipes and updating their content through the Contentful CMS"
          liveL="https://bellasrecipes.netlify.app/"
          codeL="https://github.com/8080co/BellasRecipes"
          image="/bellaRe.jpg"
          stack={["React", "Material UI", "Gatsby", "Contentful"]}
        />
        <Project
          name="Crypto-Currency Tracker"
          description="An app that tracks various crypto currencies’ prices, volume and market value by making a simple API request"
          liveL="https://cryptomarkettracker.netlify.app/"
          codeL="https://github.com/8080co/CryptoCurrencyTracker"
          image="/crypto.jpg"
          stack={["React", "Material UI", "Axios"]}
        />
      </Box>
      <Contact />
    </Box>
  );
};

export default Portfolio;

import { ThemeProvider,createTheme } from '@mui/material';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route,Routes } from 'react-router-dom';
import App from './App';
import Portfolio from './components/Portfolio'

const theme= createTheme({
 palette:{
   cardText:'#ffffff'
 },
  typography:{
    fontFamily:'Gelasio, serif',
  },
  
})
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path='/' element={<App/>}/>
        <Route path='/portfolio' element={<Portfolio/>}/>
      </Routes>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);


